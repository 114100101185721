import React from "react";
import logo from "../images/logo.png";
import Layout from "../components/layout";

const IndexPage = () => (
  <Layout>
    <div className="container">
      <div className="logo">
        <img src={logo} alt="Jashlabs logo"></img>
      </div>
      <div className="description">We build and invest in digital products</div>
      <div className="contact">info@jashlabs.com</div>
    </div>
  </Layout>
);

export default IndexPage;
